<template>
    <table>
        <thead>
        <tr>
            <td>{{ $t('table.Validator') }}</td>
            <td class="text-center">{{ $t('table.LockedAXM') }}</td>
            <td class="text-center">
                <div class="more-wide-inline">
                    {{ $t('table.delegationFee') }}
                    <PopperTooltip class="light" arrow placement="bottom"
                                   :content="$t('tooltips.delegationFee')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{$t('tooltips.delegationFee')}}</div>
                        </template>
                    </PopperTooltip>
                </div>

            </td>
            <td class="text-center">
                <div class="more-wide-inline">
                    {{ $t('table.txFee') }}
                    <PopperTooltip class="light" arrow placement="bottom"
                                   :content="$t('tooltips.txFee')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{$t('tooltips.txFee')}}</div>
                        </template>
                    </PopperTooltip>
                </div>
            </td>
            <td class="text-center">{{ $t('table.VotingPower') }}</td>
            <td class="text-right">{{ $t('table.Uptime') }}</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(validator,index) in validators" :key="index" class="table-row">
            <td class="default">
                <router-link :to="{ name: 'Validator', params: { id: validator.address }}">
                    <div class="proposerRow">
                        <div class="proposer-icon" :style="'background-image: url(' + validator.icon + ')'"></div>
                        <div>
                            <div class="blue-text">{{ validator.name }}</div>
                            <div class="proposer-address grey">{{ shrinkString(validator.userAddress, 15, 6) }}</div>
                        </div>
                    </div>
                </router-link>
            </td>
            <td class="default text-center text-nowrap">{{ Math.round(validator.locked).toLocaleString() }} AXM</td>
            <td class="default text-center">{{ validator.delegationFee }}%</td>
            <td class="default text-center">{{ validator.txFee }}%</td>
            <td class="default text-center">{{ validator.votingPower }}</td>
            <td class="default text-right">{{ validator.uptime }}%</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "ValidatorsTable",
    props: {
        validators: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {}
}
</script>

<style scoped>
.more-wide-inline{
    white-space: nowrap;
    padding-right: 20px;
}
</style>