<template>
    <table>
        <thead>
        <tr>
            <td>{{ $t('table.Type') }}</td>
            <td>{{ $t('table.Block') }}</td>
            <td>{{ $t('table.EventAmount') }}</td>
            <td>{{ $t('table.EventRelated') }}</td>
            <td>{{ $t('table.Timestamp') }}</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(event ,index) in events" :key="index" class="table-row">
            <td>
                <div class="inline">
                    <div class="message">
                        <span class="message-gradient">{{ event.message }}</span></div>
                </div>
            </td>

            <td class="blue-linear"><component :is="blockTag" :to="{ name: 'Block', params: { id: event.height }}">{{ event.height.toLocaleString() }}</component></td>
            <td>
                <div v-if="JSON.parse(event.amount)[0]?.amount">{{Number(JSON.parse(event.amount)[0].amount + 'e-6') || 0}} AXM</div>
                <div v-else>{{Number(event.amount + 'e-6') || 0}} AXM</div>
            </td>
            <td>
                <div v-for="(address ,index) in event.related" :key="index">{{ address }}</div>
            </td>
            <td class="default">{{ timeCookingUTC(event.txTime, true) }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "EventsTable",
    props: {
        events: {
            type: Array,
            default: () => ([])
        },
        blockLink: {
            type: Boolean
        }
    },

    computed: {
        blockTag() {
            return this.blockLink ? 'router-link' : 'div'
        }
    }
}
</script>

<style scoped>

</style>