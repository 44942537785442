<template>
    <div class="address-page">
        <div class="page-title">
            <div class="backButton" @click="goBack"></div>
            {{ $t('message.addressDetails') }}
        </div>
        <address-statistic :address="address"></address-statistic>
        <div class="info-table-block">
            <div class="table-selector">
                <div class="table-selector-item" :class="active =='team' ? 'active' : ''"
                     @click="switchTab('team')">{{ $t('table.Team') }}
                </div>
                <div class="table-selector-item" :class="active =='transactions' ? 'active' : ''"
                     @click="switchTab('transactions')">{{ $t('table.Transactions') }}
                </div>
                <div class="table-selector-item" :class="active =='evts' ? 'active' : ''"
                     @click="switchTab('evts')">{{ $t('table.Events') }}
                </div>
            </div>
            <div class="filters" v-if="active==='team'">
                <div class="filter-block flex-grow-last-element" ref="filters" style="position: relative">
                    <search-input @search="searchByText" :use-timeout="true"
                                  placeholder="byNickname"></search-input>
                    <radio-drop @setOption="setSearchLevel" :options="optionsFilter1" :idname="'level'"
                                :title="$t('table.Level') " type="levels"
                                class="side-dropdown"></radio-drop>

                    <radio-drop @setOption="setSearchStatus" :options="optionsFilter2" :idname="'status'"
                                :title="$t('table.Status') "
                                class="side-dropdown"></radio-drop>
                    <clear-button @clean="cleanFilters"></clear-button>

                    <div class="right-filter-block">
                        {{ $t('table.searchedCount') }}: {{ totalRecords }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="active==='team'" class="info-table">
                    <team-table @update-sort="updateSort" v-if="team.data.length" :team="team.data"></team-table>
                </div>
                <div v-if="active==='transactions'" class="info-table">
                    <div v-if="transactionsFetchFinished && !transactions.data.length" class="not-found">{{ $t('toasts.nothingFound') }}</div>
                    <transactions-table v-if="transactions.data.length"
                                        :transactions="transactions.data"></transactions-table>
                </div>
                <div v-if="active==='evts'" class="info-table">
                    <div v-if="eventsFetchFinished && !evts.data.length" class="not-found">{{ $t('toasts.nothingFound') }}</div>
                    <events-table v-if="evts.data.length" :events="evts.data" block-link></events-table>
                </div>
            </div>
            <div class="table-nav">
                <div class="show-all grey-button button" style="visibility: hidden; display: none">
                    {{ $t('message.showAll') }}
                </div>
                <explorer-paginator v-if="active === 'transactions' && transactions.data.length"
                                    :current-page="transactions.current_page"
                                    :last-page="transactions.last_page"
                                    @pagePlusArgument="pagePlusArgument" @prevPage="prevPage"
                                    @nextPage="nextPage" @goLastPage="goLastPage"/>
                <explorer-paginator v-if="active === 'team' && team.data.length" :current-page="team.current_page"
                                    :last-page="team.last_page"
                                    @pagePlusArgument="pagePlusArgument" @prevPage="prevPage"
                                    @nextPage="nextPage" @goLastPage="goLastPage"/>
                <explorer-paginator v-if="active === 'evts' && evts.data.length" :current-page="evts.current_page"
                                    :last-page="evts.last_page"
                                    @pagePlusArgument="pagePlusArgument" @prevPage="prevPage"
                                    @nextPage="nextPage" @goLastPage="goLastPage"/>
                <show-lines-count v-if="currentTabLength" @count="setPageCount"></show-lines-count>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {
    TRANSACTIONS_LIST,
    TRANSACTIONS_COUNT,
    ACCOUNT,
    EVENTS_USER_COUNT,
    EVENTS_LIST,
    TEAM
} from "../../../routes/routes"
import TransactionsTable from "@/components/Transactions/TransactionsTable";
import ExplorerPaginator from "@/components/Tools/ExplorerPaginator";
import ShowLinesCount from "@/components/Filters/ShowLinesCount";
import TeamTable from "@/components/Accounts/TeamTable";
import SearchInput from "@/components/Filters/SearchInput";
import RadioDrop from "@/components/Filters/RadioDrop";
import ClearButton from "@/components/Tools/ClearButton";
import EventsTable from "@/components/Accounts/EventsTable";
import AddressStatistic from "@/components/Accounts/AddressStatistic";
import {statuses} from "@/mixins/statuses";

export default {
    name: "OneAddress",
    mixins: [statuses],
    components: {
        AddressStatistic,
        EventsTable,
        ClearButton, RadioDrop, SearchInput, TeamTable, ShowLinesCount, ExplorerPaginator, TransactionsTable
    },
    props: {},
    data() {
        return {
            currentAddress: '',
            user: {},
            active: '',
            textSearch: '',
            searchStatus: '',
            searchLevel: '',
            optionsFilter1: [
                {label: '1', value: 1},
                {label: '2', value: 2},
                {label: '3', value: 3},
                {label: '4', value: 4},
                {label: '5', value: 5},
                {label: '6', value: 6},
                {label: '7', value: 7},
                {label: '8', value: 8},
                {label: '9', value: 9},
                {label: '10', value: 10},
                {label: '11', value: 11},
                {label: '12', value: 12},
                {label: '13', value: 13},
                {label: '14', value: 14},
            ],
            optionsFilter2: [
                {label: this.$t('status.Starter'), value: 1},
                {label: this.$t('status.Leader'), value: 2},
                {label: this.$t('status.Guru'), value: 3},
                {label: this.$t('status.Boss'), value: 4},
                {label: this.$t('status.Pro'), value: 5},
                {label: this.$t('status.Top'), value: 6},
                {label: this.$t('status.Mega'), value: 7},
            ],
            totalRecords: 0,
            address: {
                address: '',
                ClaimableRewards: 0,
                UAXM: 0,
                UAXMD: 0,
                teamSize: 0,
                teamSizeDaily: 0,
                teamBalance: 0,
                teamDelegated: 0,
                partnerRewards: 0,
                nickname: '',
                referrer: '',
                confirmed: false
            },
            elementsOnPage: 10,
            transactionsFetchFinished: false,
            transactions: {
                current_page: 1,
                data: [],
                last_page: 0
            },
            eventsFetchFinished: false,
            evts: {
                current_page: 1,
                data: [],
                last_page: 0
            },
            team: {
                current_page: 1,
                last_page: 0,
                order: {
                    field: null,
                    direction: 1
                },
                data: []
            },
        }
    },
    computed: {
        currentTabLength() {
            return this[this.active].data.length
        }
    },
    created() {
        this.currentAddress = this.$router.currentRoute._value.params.id;
        this.address.address = this.$router.currentRoute._value.params.id;
        this.fetchAddressInfo()
        this.getTransactionsCount()
        this.switchTab('team')
    },

    methods: {
        fetchAddressInfo() {
            let params = '?fields=balance,claimable,referral,ref_info,claimed_total'
            axios({
                method: "get",
                url: ACCOUNT + this.currentAddress + params,
                timeout: 1000 * 20,
            }).then(response => {
                let balances = response.data?.balance
                this.address.UAXM = Number(balances?.find(elem => elem.denom === 'uaxm')?.amount + 'e-6') || 0
                this.address.UAXMD = Number(balances?.find(elem => elem.denom === 'uaxmd')?.amount + 'e-6') || 0

                let referral = response.data?.referral
                if (referral) {
                    this.address.teamSize = referral.teamSize
                    this.address.teamSizeDaily = referral.teamSizeDaily
                    this.address.teamBalance = referral.teamBalance
                    this.address.teamDelegated = referral.teamDelegated
                    this.address.partnerRewards = referral.totalRewards
                    this.address.nickname = referral.name
                    this.address.status = referral.status
                    this.address.confirmed = referral.confirmed_account

                    let referrerAddress = referral.referrer
                    if (referrerAddress) {
                        axios({
                            method: "get",
                            url: ACCOUNT + referrerAddress + params,
                            timeout: 1000 * 20,
                        }).then(response => {
                            let referrer = response.data?.referral
                            this.address.referrer = referrer.name
                            this.address.referrerAddress = referrer._id
                            this.address.referrerConfirmed = referrer.confirmed_account
                        }).catch(error => {
                            if (error.code === 'ECONNABORTED') {
                                console.log('Request timed out');
                            } else {
                                console.log(error.message);
                            }
                        });
                    }
                } else {
                    this.address.status = 0
                }

                this.address.ClaimableRewards = Math.round(response.data?.claimable?.total[0]?.amount || 0)
                this.address.ClaimableRewards = Number(this.address.ClaimableRewards + 'e-6') || 0

                this.address.partnerRewards = Number((response.data?.referralClaimedTotal || 0) + 'e-6');
                this.address.UAXMD = Number((response.data?.refInfo?.self_delegated || 0) + 'e-6')
                this.address.teamDelegated = Number((response.data?.refInfo?.team_delegated || 0) + 'e-6');
                this.address.teamBalance = Number((this.address.teamBalance || 0) + 'e-6') + this.address.teamDelegated;

            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        getTeamCount() {
            let params = ''
            params = this.searchLevel || this.searchStatus || this.textSearch ? '?' : ''
            params = this.textSearch ? params + 'q=' + this.textSearch : params
            params = this.searchLevel ? params + '&level=' + this.searchLevel : params;
            params = this.searchStatus ? params + '&status=' + this.searchStatus : params;

            axios({
                method: "get",
                url: TEAM + this.currentAddress + '/paginate/records_count' + params,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.totalRecords = info?.total_records || 0
                this.team.last_page = Math.ceil(info?.total_records / this.elementsOnPage)
                this.fetchTeamMembers()
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchTeamMembers() {
            let params = ''
            params = this.searchLevel || this.searchStatus || this.textSearch || this.team.order.field ? '?' : ''
            params = this.textSearch ? params + 'q=' + this.textSearch : params
            params = this.searchLevel ? params + '&level=' + this.searchLevel : params;
            params = this.searchStatus ? params + '&status=' + this.searchStatus : params;
            params = this.team.order.field ? params + '&order=' + this.team.order.field + '&direction=' + this.team.order.direction : params;
            axios({
                method: "get",
                url: TEAM + this.currentAddress + '/paginate/' + this.elementsOnPage + '/' + this.team.current_page + params,
                timeout: 1000 * 20,
            }).then(response => {
                this.team.data = []
                let info = response.data
                info.data?.forEach((user) => {
                    let status = user.status !== -1 ? user.status : 0
                    this.team.data.push({
                        nickname: user.name,
                        address: user._id,
                        logoUrl: this.statusesSamples[status]?.icon,
                        status: this.statusesSamples[status]?.name,
                        level: Object.entries(user.referrersByLevel).find((level) => {
                            return level[1] === this.currentAddress
                        })[0].replace(/\D/g, ''),
                        delegated: Number(user.delegated + 'e-6') || 0,
                        teamCnt: user.teamSize,
                        teamCntDaily: user.teamSizeDaily,
                        teamActive: user.activeTeam,
                        referrer: info?.names[user.referrer],
                        referrerAddress: user.referrer,
                    })
                })
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        getEventsCount() {
            let params = '?address=' + this.currentAddress
            axios({
                method: "get",
                url: EVENTS_USER_COUNT + params,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.evts.last_page = Math.ceil(info?.totalRecords / this.elementsOnPage)
                this.fetchEvents()
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchEvents() {
            let params = '?address=' + this.currentAddress
            axios({
                method: "get",
                url: EVENTS_LIST + this.elementsOnPage + '/' + this.evts.current_page + params,
                timeout: 1000 * 20,
            }).then(response => {
                this.evts.data = []
                let info = response.data
                info.data?.forEach((event) => {
                    this.evts.data.push({
                        height: event.blockId,
                        message: event.type,
                        txTime: this.$dayjs(event.timestamp).unix(),
                        related: event.related,
                        amount: event.attributes?.find(el => el.key === "amount" || el.key === "ucoins")?.value || 0
                    })
                })
                this.eventsFetchFinished = true
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        getTransactionsCount() {
            let params = '?address=' + this.currentAddress
            axios({
                method: "get",
                url: TRANSACTIONS_COUNT + params,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.transactions.last_page = Math.ceil(info.totalRecords / this.elementsOnPage)
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchTransactions() {
            let params = '?address=' + this.currentAddress
            axios({
                method: "get",
                url: TRANSACTIONS_LIST + this.elementsOnPage + '/' + this.transactions.current_page + params,
                timeout: 1000 * 20,
            }).then(response => {
                this.transactions.data = []
                let info = response.data
                info.data?.forEach((transaction) => {
                    this.transactions.data.push({
                        messages: transaction.type,
                        hash: transaction._id,
                        height: transaction.blockId,
                        status: transaction.code,
                        txTime: this.$dayjs(transaction.timestamp).unix(),
                    })
                })
                this.transactionsFetchFinished = true
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        cleanFilters() {
            this.bus.emit('clean')
            this.searchLevel = ''
            this.searchStatus = ''
            this.textSearch = ''
            this[this.active].current_page = 1;
            this.getTeamCount()
        },
        searchByText(e) {
            this.textSearch = e
            this[this.active].current_page = 1;
            this.getTeamCount()
        },
        setSearchLevel(e) {
            this.searchLevel = e
            this[this.active].current_page = 1;
            this.getTeamCount()
        },
        setSearchStatus(e) {
            this.searchStatus = e
            this[this.active].current_page = 1;
            this.getTeamCount()
        },
        setPageCount(e) {
            this.elementsOnPage = e
            this[this.active].current_page = 1
            this.reloadCurrentTabData()
        },
        prevPage() {
            if (this[this.active].current_page > 1) {
                this[this.active].current_page = this[this.active].current_page - 1
            }
            this.reloadCurrentTabData()
        },
        nextPage() {
            if (this[this.active].current_page < this[this.active].last_page) {
                this[this.active].current_page = this[this.active].current_page + 1
            }
            this.reloadCurrentTabData()
        },
        goLastPage() {
            this[this.active].current_page = this[this.active].last_page
            this.reloadCurrentTabData()
        },
        pagePlusArgument(e) {
            this[this.active].current_page = this[this.active].current_page + e
            this.reloadCurrentTabData()
        },
        switchTab(item) {
            this.active = item
            this.reloadCurrentTabData()
        },
        reloadCurrentTabData() {
            switch (this.active) {
                case "team":
                    this.getTeamCount();
                    break;
                case "transactions":
                    this.fetchTransactions();
                    break;
                case "evts":
                    this.getEventsCount();
                    break;
            }
        },

        updateSort(sort) {
            this.team.order.field = sort.order;
            this.team.order.direction = sort.direction;
            this.getTeamCount()
        }
    }
}
</script>

<style scoped>
.not-found {
    margin: 0 auto;
    width: 100%;
    margin-top: 10px;
    text-align: center;
    color: #9A9DAB;
    padding-bottom: 20px;
}
</style>