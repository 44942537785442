<template>
    <div class="block-detail-info address-detail">
        <div class="block-detail-info-header">
            <div class="block-number space-between-container inline">
                <div class="title">{{ $t('table.Information') }}</div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Address') }}</div>
                <div class="block-number inline">
                    <div class="subvalue-1 inline">
                        {{ shrinkString(address.address, 25, 6) }}
                        <copy-button :text="address.address"></copy-button>
                    </div>
                </div>
            </div>
            <div class="info-block space-between-container" v-if="address.nickname">
                <div class="subtitle-1 inline">{{ $t('table.Nickname') }}</div>
                <div class="block-number inline">
                    <div class="subvalue-1 inline">
                        <span v-if="address.confirmed" class="confirm-icon"></span>{{ address.nickname }}
                        <copy-button :text="address.nickname"></copy-button>
                    </div>
                </div>
            </div>
            <div class="info-block space-between-container" v-if="address.referrer">
                <div class="subtitle-1 inline">{{ $t('table.Referrer') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.Referrer') }}</div>
                        </template>
                    </PopperTooltip>
                </div>

                <div class="subvalue-1 inline blue-text">
                    <span v-if="address.referrerConfirmed" class="confirm-icon"></span>
                    <router-link :to="{ name: 'Address', params: { id: address.referrerAddress }}">{{
                        address.referrer
                        }}
                    </router-link>
                </div>

            </div>
        </div>
        <div class="block-detail-info-header border-left border-right">
            <div class="block-number space-between-container inline">
                <div class="title">{{ $t('table.Balance') }}</div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Total') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content">{{ $t('tooltips.addressBalanceTotal') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">{{
                    (+((address.UAXM + address.UAXMD).toFixed(1)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Available') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressAvailable') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">{{
                    (+(address.UAXM.toFixed(1)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Delegated') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressDelegated') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">{{
                    (+(address.UAXMD.toFixed(1)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.ClaimableRewards') }}
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressClaimable') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">
                    {{ (+(address.ClaimableRewards.toFixed(1)) || 0).toLocaleString().replace(",", ".") }} AXM
                </div>
            </div>
        </div>
        <div class="block-detail-info-header">
            <div class="space-between-container space-between-container-narrow-m inline">
                <div class="title">{{ $t('table.Partnership') }}</div>
                <router-link :to="{ name: 'Progress', params: { id: address.address }}">
                    <div class="status_data">
                        <div class="status_name blue-text">{{ statusesSamples[address.status]?.name }}</div>
                        <div class="status-icon"
                             :style="'background-image: url(' + statusesSamples[address.status]?.icon + ')'"></div>
                    </div>
                </router-link>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.TeamSize') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressTeamSize')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressTeamSize') }}</div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">{{ (address.teamSize || 0).toLocaleString() }}
                    &nbsp;<span class="subtitle-1 inline padding-r-0">(+{{ (address.teamSizeDaily || 0).toLocaleString() }})</span>
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.TeamBalance') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressTeamBalance')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{ $t('tooltips.addressTeamBalance') }}
                            </div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">
                    {{ (+address.teamBalance.toFixed(1)).toLocaleString().replace(",", ".") || '-' }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.Teamdelegation') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressTeamdelegation')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">
                                {{ $t('tooltips.addressTeamdelegation') }}
                            </div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline">
                    {{ (+(address.teamDelegated.toFixed(1)) || 0).toLocaleString().replace(",", ".") }} AXM
                </div>
            </div>
            <div class="info-block space-between-container">
                <div class="subtitle-1 inline">{{ $t('table.PartnerRewards') }}
                    <PopperTooltip class="light" arrow placement="top"
                                   :content="$t('tooltips.addressPartnerRewards')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">
                                {{ $t('tooltips.addressPartnerRewards') }}
                            </div>
                        </template>
                    </PopperTooltip>
                </div>
                <div class="subvalue-1 inline" :class="+(address.partnerRewards.toFixed(1)) > 0 ? 'positive-text' : ''">
                    {{ +(address.partnerRewards.toFixed(1)) > 0 ? '+' : '' }}
                    {{
                    (+(address.partnerRewards.toFixed(1)) || 0).toLocaleString().replace(",", ".")
                    }} AXM
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {statuses} from "@/mixins/statuses";
import CopyButton from "@/components/Tools/CopyButton";

export default {
  name: "AddressStatistic",
  components: {CopyButton},
  mixins: [statuses],
  data() {
    return {}
  },
  props: {
    address: {
      type: Object,
      default: () => ({})
    }
  },
}
</script>

<style scoped>

</style>