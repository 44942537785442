<template>
    <div>
        <div class="page-title">
            <div class="backButton" @click="goBack"></div>
            {{ $t('message.transactionDetails') }}
        </div>
        <transaction-info v-if="transaction"
                          :transaction="transaction"
                          :messages="messages"
                          :events="events"></transaction-info>
    </div>
</template>

<script>
import {TRANSACTION} from "../../../routes/routes"
import TransactionInfo from "@/components/Transactions/TransactionInfo";
import axios from "axios";

export default {
    name: "OneTransaction",
    components: {TransactionInfo},
    data() {
        return {
            currentTransaction: '',
            messages: {},
            transaction: {},
            events: {}
        }
    },
    created() {
        this.currentTransaction = this.$router.currentRoute._value.params.id;
        this.fetchTransaction()
    },
    mounted() {},

    methods: {
        fetchTransaction(){
            axios({
                method: "get",
                url: TRANSACTION + this.currentTransaction,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.transaction = {
                    message: info.type,
                    hash: info._id,
                    height: info.blockId,
                    status: info.code,
                    txTime: this.$dayjs(info.timestamp).unix(),
                    gas: {
                        used: info.gasUsed,
                        requested: info.gasWanted,
                    },
                    fee: info.fee,
                    chainId: info.chainId,
                }
                this.messages = info.tx.body.messages
                this.events = info.evts
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        }
    },
}
</script>

<style scoped>

</style>