<template>
    <table>
        <thead>
        <tr>
            <td>{{ $t('table.Message') }}</td>
            <td>{{ $t('table.TXNHash') }}</td>
            <td>{{ $t('table.Block') }}</td>
            <td>{{ $t('table.Status') }}</td>
            <td class="text-right" style="padding-right: 10px;">{{ $t('table.Timestamp') }}</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(tx,index) in transactions" :key="index" class="table-row">
            <td>
                <div class="inline">
                    <PopperTooltip class="light" arrow placement="top">
                        <div class="message"><span class="message-gradient">{{ tx.messages && tx.messages[0] }}</span>
                        </div>

                        <template #content v-if="tx.messages && tx.messages.length > 1">
                            <div class="tooltipContent">
                                <div class="message" v-for="(item, index) in tx.messages" :key="index">
                                    <span class="message-gradient" v-if="index > 0">{{ item }}</span>
                                </div>
                            </div>
                        </template>
                    </PopperTooltip>

                    <span class="plus-count" v-if="tx.messages && (tx.messages.length-1) > 0">+{{
                            tx.messages.length - 1
                        }}</span>
                </div>
            </td>
            <td class="blue-linear">
                <transaction-hash :hash="tx.hash" />
            </td>

            <td v-if="highlightBlockNumber" class="blue-linear">
                <router-link :to="{ name: 'Block', params: { id: tx.height }}">
                    {{ tx.height.toLocaleString() }}
                </router-link>
            </td>
            <td v-else>
                {{ tx.height.toLocaleString() }}
            </td>
            <td v-if="tx.status == 0" class="status positive-text">{{ $t('table.Success') }}</td>
            <td v-else class="status negative-text">{{ $t('table.Error') }} ({{ tx.status }})</td>
            <td class="default text-right" style="padding-right: 10px;">{{ timeCookingUTC(tx.txTime, true) }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import TransactionHash from "@/components/Transactions/TransactionHash";
export default {
    name: "TransactionsTable",
    components: {TransactionHash},
    props: {
        highlightBlockNumber: {
            type: Boolean,
            default: true
        },
        transactions: {
            type: Array,
            default: () => ([])
        }
    },
}
</script>

<style scoped>

</style>