<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "ToolModalInner",
    data() {
        return {
            open: false,
            isMoving: false,
            deltaY: 0,
            startY: 0,
            noStyle: true,
            needVibrate: true,
            y_offsetWhenScrollDisabled: 0
        }
    },
    props: {
        openModal: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.bus.on('hide', this.hideModal)
    },
    unmounted() {
        if (this.needVibrate) {
            this.needVibrate = false;
        }
    },
    methods: {
        hideModal() {
            this.open = false;
            this.$emit('hidePopup')
        },
    },
    watch: {
        openModal() {
            this.open = this.openModal;
        }
    }
})
</script>

<template>
    <transition name="custom-toolbox">
        <div class="tool-modal popup-modal" v-if="open">
            <transition name="inner-toolbox" :key="index" appear>
                <div class="tool-popup_inner" v-click-outside="hideModal"
                     ref="modalInner">
                    <slot/>
                    <div class="close" @click="hideModal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5ZM15.3588 15.8587C15.0659 16.1516 14.591 16.1516 14.2981 15.8587L12 13.5606L9.7019 15.8587C9.40901 16.1516 8.93413 16.1516 8.64124 15.8587C8.34835 15.5658 8.34835 15.0909 8.64124 14.798L10.9393 12.4999L8.64125 10.2018C8.34836 9.90895 8.34836 9.43408 8.64125 9.14118C8.93415 8.84829 9.40902 8.84829 9.70191 9.14118L12 11.4393L14.2981 9.14119C14.591 8.8483 15.0659 8.8483 15.3588 9.14119C15.6516 9.43409 15.6516 9.90896 15.3588 10.2019L13.0607 12.4999L15.3588 14.798C15.6517 15.0909 15.6517 15.5658 15.3588 15.8587Z" fill="#9A9DAB"/>
                        </svg>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<style scoped>
.custom-toolbox-enter-active,
.custom-toolbox-leave-active {
    transition: opacity .2s ease-in;
}

.custom-toolbox-enter-from, .custom-toolbox-leave-to {
    opacity: 0;
}

.inner-toolbox-enter-active, .inner-toolbox-leave-active {
    transition: transform .2s ease-in;
    z-index: 100;
}

.inner-toolbox-enter-from,
.inner-toolbox-leave-to {
    transform: translateY(100%) !important;
}
</style>