<template>
    <div class="validators-page">
        <div class="page-title core-page-title">
            {{ $t('message.overview') }}
        </div>
        <div class="statistic validtors-statistic">
            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.TotalValidators') }}
                </div>
                <div class="value">
                    {{ statistic.total.toLocaleString() }}
                </div>
            </div>

            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.AvTPS') }}
                </div>
                <div class="value">
                    {{  statistic.avTPS.toLocaleString() }} <span class="currency">AXM</span>
                </div>
            </div>

            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.AvEventsPerSecond') }}
                </div>
                <div class="value">
                    {{ statistic.avEPS.toFixed(2) }}%
                </div>
            </div>

            <div class="statistic-block">
                <div class="title">
                    {{ $t('table.MemPoolSize') }}
                </div>
                <div class="value">
                    {{ statistic.memPool.toLocaleString() }}
                </div>
            </div>
        </div>
        <div class="info-table-block">
            <div class="filters">
                <pair-buttons :button1="$t('table.ActiveValidators')" :count1="statistic.active"
                              :button2="$t('table.InactiveValidators')" :count2="statistic.total - statistic.active"
                              @clickOne="sortActive" @clickTwo="sortNoActive"
                ></pair-buttons>
                <div class="filter-block">
                    <search-input @search="textSearch" placeholder="byValidators"></search-input>
                </div>
            </div>

            <div class="row">
                <div class="info-table">
                    <validators-table :validators="cuttedValidators"></validators-table>
                </div>
            </div>
<!--            <div class="table-nav">-->
<!--                <div class="show-all grey-button button" style="visibility: hidden; display: none">-->
<!--                    {{ $t('message.showAll') }}-->
<!--                </div>-->
<!--                <explorer-paginator :current-page="validators.current_page" :last-page="validators.last_page"-->
<!--                                    @pagePlusArgument="pagePlusArgument" @prevPage="prevPage"-->
<!--                                    @nextPage="nextPage" @goLastPage="goLastPage"/>-->
<!--                &lt;!&ndash;                <show-lines-count></show-lines-count>&ndash;&gt;-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import {CHAIN_STATISTIC_CACHE, VALIDATORS} from "../../../routes/routes"
import PairButtons from "@/components/Filters/PairButtons";
import ValidatorsTable from "@/components/Validators/ValidatorsTable";
import SearchInput from "@/components/Filters/SearchInput";
import axios from "axios";
const {bech32} = require('bech32');

export default {
    name: "ValidatorsOverview",
    components: {SearchInput, ValidatorsTable, PairButtons},
    data() {
        return {
            activeOnly: true,
            pageCount: 500,
            statistic: {
                total: 0,
                active: 0,
                avTPS: 0,
                avEPS: 0,
                memPool: 0,
            },
            filteredValidators: [],
            cuttedValidators: [],
            validators: {
                current_page: 1,
                data: [],
                last_page: 1
            },
        }
    },
    created() {
        this.fetchValidators()
        this.getStatistic()
    },
    methods: {
        fetchValidators() {
            let params = ''

            axios({
                method: "get",
                url: VALIDATORS
                    + params,
                timeout: 1000 * 20,
            }).then(response => {
                this.validators.data = []
                this.statistic.total = response.data?.length
                this.statistic.avEPS = 0;
                this.statistic.avTPS = 0;
                response.data.forEach((item) => {
                    this.statistic.active = item.isActive ? this.statistic.active + 1 : this.statistic.active;

                    let decode = bech32.decode(item.bech32Address)
                    let bits = new Uint32Array(decode.words);
                    let prefix = 'axm';
                    let address = bech32.encode(prefix, bits)

                    let val = {
                        name: item.name,
                        address: item.bech32Address,
                        userAddress: address,
                        icon: item.logoUrl,
                        isActive: item.isActive,
                        delegationFee: item.delegationFee,
                        txFee: item.txFee,
                        locked: Number(item.locked + 'e-6'),
                        votingPower: item.votingPower,
                        uptime: (item.okBlocksSinceStart / item.blocksSinceStart) * 100,
                        webSite: item.siteUrl,
                        securityContact: item.securityContact,
                    }
                    this.validators.data.push(val)
                    this.statistic.avTPS += val.locked;
                    this.statistic.avEPS += val.uptime;
                })

                this.statistic.avEPS /= response.data.length;
                this.statistic.avTPS = Math.round(this.statistic.avTPS);

                this.validators.last_page = Math.ceil(this.validators.data.length/this.pageCount)
                this.validators.current_page = 1;
                this.filteredValidators = this.validators.data

                this.cutElementsForPage()

            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        getStatistic() {
            axios({
                method: "get",
                url: CHAIN_STATISTIC_CACHE,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                // this.statistic.avEPS = info?.eventsPerSecond || 0
                // this.statistic.avTPS = info?.txsPerSecond || 0
                this.statistic.memPool = info?.mempool?.n_txs || 0
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        cutElementsForPage(){
            this.cuttedValidators=[]
            this.validators.last_page = Math.ceil(this.filteredValidators.length/this.pageCount)
            this.cuttedValidators = this.filteredValidators.slice(
                (this.validators.current_page-1) * this.pageCount,
                ((this.validators.current_page-1) * this.pageCount)+this.pageCount)
        },
        sortArray(){
            if(this.activeOnly){
                this.filteredValidators = this.validators.data.filter(item => item.isActive)
            }else{
                this.filteredValidators = this.validators.data.filter(item => !item.isActive)
            }
            if(this.textForSearch?.length){
                this.filteredValidators = this.filteredValidators.filter(item =>
                    item.name.toLowerCase().includes(this.textForSearch?.toLowerCase()) ||
                    item.address.toLowerCase().includes(this.textForSearch?.toLowerCase()));
            }

            this.cutElementsForPage()
        },
        textSearch(text) {
            this.textForSearch = text
            this.validators.current_page = 1
            this.sortArray()
        },
        sortActive() {
            this.activeOnly = true
            this.validators.current_page = 1
            this.sortArray()
        },
        sortNoActive() {
            this.activeOnly = false
            this.validators.current_page = 1
            this.sortArray()
        },
        prevPage() {
            if (this.validators.current_page > 1) {
                this.validators.current_page = this.validators.current_page - 1
                this.cutElementsForPage()
            }
        },
        nextPage() {
            if (this.validators.current_page < this.validators.last_page) {
                this.validators.current_page = this.validators.current_page + 1
                this.cutElementsForPage()
            }
        },
        goLastPage() {
            this.validators.current_page = this.validators.last_page
            this.cutElementsForPage()
        },
        pagePlusArgument(e) {
            this.validators.current_page = this.validators.current_page + e
            this.cutElementsForPage()
        },
    }
}
</script>

<style scoped>

</style>