<template>
    <div v-if="!maintenance" class="header-container">
        <meta :title="metaInfo.title"/>
        <header class="header" :class="mobileMenuOpen? 'fixed' : ''">
            <a href="/" class="logo"></a>
            <input class="side-menu" type="checkbox" id="side-menu" v-model="menu"/>
            <label @click="mobileMenuOpen = !mobileMenuOpen" class="hamb" for="side-menu"><span
                class="hamb-line"></span></label>
            <nav class="nav mobileOnly">
                <global-search placeholder="menu" @search-submit="closeMenu"></global-search>
                <menu-items @clicked="menuClicked"></menu-items>

                <div class="menu-bottom">
                    <lang-selector></lang-selector>
                        <div class="testnet selected-options">
                            <span class="text">{{ $t('message.Mainnet') }}</span>
                        </div>
                    <div class="copyright"> {{ $t('message.Copyright') }}</div>
                </div>
            </nav>
            <div class="nav desktopOnly">
                <lang-selector></lang-selector>
                <menu-dropdown></menu-dropdown>
                <div>
                    <div class="testnet selected-options">
                        <span class="text">{{ $t('message.Mainnet') }}</span>
                    </div>
                </div>
            </div>
        </header>
    </div>
    <div v-if="!maintenance" class="body">
        <router-view :key="$route.fullPath"></router-view>
    </div>
    <div v-if="!maintenance" class="copyright desktopOnly"> {{ $t('message.Copyright') }}</div>
    <div v-if="maintenance" class="maintenance">
        <div class="maintenance-item"><img class="maintenance-logo" src="@/assets/logo.png"></div>
        <div class="maintenance-item">
            We are preparing the Mainnet for launch. All data will appear after the start. More news in our Telegram channels.
        </div>
        <div class="maintenance-links maintenance-item">
            <a href="https://t.me/axiomeen" target="_blank"><img src="@/assets/icons/telegram.svg">Telegram Channel</a>
            <a href="https://t.me/axiomeru" target="_blank"><img src="@/assets/icons/telegram.svg">Телеграм Канал</a>
            <a href="https://t.me/axiomede" target="_blank"><img src="@/assets/icons/telegram.svg">Telegram-Kanal</a>
        </div>
    </div>
</template>

<script>
import MenuDropdown from "@/components/Tools/MenuDropdown";
import MenuItems from "@/components/Tools/MenuItems";
import LangSelector from "@/components/Tools/LangSelector";
import GlobalSearch from "@/components/Filters/GlobalSearch";
// import axios from "axios";

export default {
    name: 'App',
    components: {
        GlobalSearch,
        LangSelector,
        MenuDropdown,
        MenuItems
    },
    data() {
        return {
            mainPage: false,
            mobileMenuOpen: false,
            menu: false,

            metaInfo: {
                title: 'Axiome Chain Explorer'
            },

            maintenance: false
        }
    },
    computed: {},
    created: function () {
        var currentUrl = window.location.pathname;
        if (currentUrl === '/') {
            this.mainPage = true
        }
    },
    mounted() {},
    methods: {
        menuClicked() {
            this.mobileMenuOpen = false;
            this.menu = false
        },

        closeMenu() {
            this.mobileMenuOpen = false;
            this.menu = false;
        }
    },
    watch: {
    },
}
</script>

<style lang="scss">
.body {

}

.language-options__item {
    padding: 8px;
}
</style>

