<template>
    <div class="cleanFilters" @click="cleanFilters">
        <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom: -2px;" width="14" height="14" viewBox="0 0 14 15" fill="none">
            <path
                d="M11.6667 5.49967L11.1894 11.7042C11.0825 13.0935 9.92401 14.1663 8.53058 14.1663H5.46942C4.07599 14.1663 2.91748 13.0935 2.8106 11.7042L2.33333 5.49967M13 4.16634C11.2681 3.32233 9.2091 2.83301 7 2.83301C4.7909 2.83301 2.73194 3.32233 1 4.16634M5.66667 2.83301V2.16634C5.66667 1.42996 6.26362 0.833008 7 0.833008C7.73638 0.833008 8.33333 1.42996 8.33333 2.16634V2.83301M5.66667 6.83301V10.833M8.33333 6.83301V10.833"
                stroke="#9A9DAB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="desktopOnly">{{$t('table.CleanFilter') }}</span>
    </div>

</template>

<script>
export default {
    name: "ClearButton",
    methods: {
        cleanFilters() {
            this.$emit('clean')
        }
    }
}
</script>

<style scoped>
</style>