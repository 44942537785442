<template>
    <div>
        <div class="page-title">
            <div class="backButton" @click="goBack"></div>
            <div style="position: relative">
                {{ $t('message.StatusProgress') }}
<!--                <PopperTooltip class="light" arrow placement="top"-->
<!--                               content="Status progress">-->
<!--                    <div class="help-icon" style="top: 12px;width: 14px; height: 14px"></div>-->
<!--                </PopperTooltip>-->
                <div class="regular-text"><span class="grey" style="padding-left: 0">{{ $t('table.User') }}: </span> {{user.nickname}}</div>
            </div>
        </div>
        <div class="info-table-block">
            <progress-table></progress-table>
        </div>
    </div>
</template>

<script>
import ProgressTable from "@/components/Accounts/ProgressTable";
import axios from "axios";
import {ACCOUNT} from "../../../routes/routes";

export default {
    name: "StatusProgress",
    components: {ProgressTable},
    data() {
        return {
            currentAddress: '',
            user:{
                nickname:'',
                progress: []
            }
        }
    },
    created() {
        this.currentAddress = this.$router.currentRoute._value.params.id;
        this.fetchAddressInfo()
    },
    methods:{
        fetchAddressInfo() {
            let params = '?fields=referral'
            axios({
                method: "get",
                url: ACCOUNT + this.currentAddress + params,
                timeout: 1000 * 20,
            }).then(response => {
                let referral = response.data?.referral

                if(referral){
                    this.user.nickname = referral.name
                }
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        }
    }

}
</script>

<style scoped>

</style>