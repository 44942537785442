<template>
    <table>
        <thead>
        <tr>
            <td>{{ $t('table.Status') }}</td>
            <td>{{ $t('table.Progress') }}</td>
            <td class="text-center"></td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in progress" :key="index" class="table-row">
            <td class="default">
                <div class="proposerRow" style="align-items: center">
                    <div class="proposer-icon"
                         :style="'background-image: url(' + statusesSamples[index+1]?.icon + ')'"></div>
                    <div>
                        <div>{{ statusesSamples[index+1]?.name }}</div>
                    </div>
                </div>
            </td>
            <td class="default progress">
                <div class="progress-number text-nowrap" v-if="item === 100">
                    {{ $t('table.Got') }}: <span class="positive-text">{{ item }}%</span>
                </div>
                <div class="progress-number text-nowrap" v-else>
                    {{ $t('table.InProcess') }}: <span class="blue-text">{{ item }}%</span>
                </div>
                <div class="progress-bar">
                    <div :class="item === 100? 'fill-green' : 'fill-blue'"
                         :style="'width:'+ item +'%' "></div>
                    <div class="fill" :style="'width:'+ item +'%' "></div>
                </div>
            </td>
            <td class="default td-button">
                <more-button @click="statusMore(index+1)"></more-button>
            </td>
        </tr>
        </tbody>
    </table>
    <Popup :openModal="openModal" @hidePopup="hidePopup">
        <div class="status-detail">
            <div class="status-detail_head">
                <div class="status-detail_head_icon"
                     :style="'background-image: url(' + openStatus.icon + ')'"></div>
                <div class="status-detail_head_name">{{ openStatus.name }}</div>
<!--                <div class="status-detail_head_name_desc grey">{{ $t('table.Status') }}</div>-->
            </div>
            <div class="status-detail_body">
                <div class="status-detail_body_advantages">
                    <div class="title">{{ $t('table.Advantages') }}</div>
                    <template v-if="openStatus.id == 'Starter'">
                    <div class="item">{{ $t('statusDetail.advanages.Starter-1') }}</div>
                    </template>
                    <div v-else  class="item" v-for="index in 2" :key="index">
                        <template v-if="$t('statusDetail.advanages.'+ openStatus.id + '-' +'n')">
                            {{ $t('statusDetail.advanages.' + openStatus.id + '-' + index) }}
                        </template>
                    </div>
                </div>
                <div class="status-detail_body_conditions">
                    <div class="title">{{ $t('table.Conditions') }}</div>
                    <template v-for="(criteria, index) in conditionsProgress[openStatusIndex].criteria" :key="index">
                        <div class="item" v-if="openStatusIndex < 3 || criteria.rule !== 'RULE_N_REFERRALS_WITH_X_REFERRALS_EACH'">
                            {{ $t('statusDetail.conditions.' + statusesConditionsObjects[openStatus.id][criteria.rule]) }}
                            <div class="item-subtext grey">
                                {{ (+criteria.actual_value).toLocaleString().replace(",", ".") }} / {{ (+criteria.target_value).toLocaleString().replace(",", ".") }}
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </Popup>
</template>

<script>
import MoreButton from "@/components/Tools/MoreButton";
import Popup from "@/components/Tools/Popup";
import {statuses} from "@/mixins/statuses";
import {statusesConditions} from "@/mixins/statusesConditions";
import axios from "axios";
import {CONDITIONS_PROGRESS} from "../../../routes/routes";

export default {
    name: "ProgressTable",
    components: {MoreButton, Popup},
    mixins: [statuses, statusesConditions],
    data() {
        return {
            openModal: false,
            conditionsProgress: [],
            progress: [],
            currentAddress: '',
            openStatusIndex: 0,
            openStatus: {},
        }
    },

    created() {
        this.currentAddress = this.$router.currentRoute._value.params.id;
        this.fetchStatusProgress();
    },

    methods: {
        hidePopup() {
            this.openModal = false
        },
        statusMore(index) {
            this.openModal = true
            this.openStatus = this.statusesSamples[index]
            this.openStatusIndex = index - 1;
        },
        fetchStatusProgress() {
            axios.get(CONDITIONS_PROGRESS + this.currentAddress)
                .then((res) => {
                    this.conditionsProgress = res.data;
                    this.progress = [];
                    this.conditionsProgress.forEach(status => {
                        this.progress.push(this.calculateStatusProgress(status.criteria))
                    })
                })
        },

        calculateStatusProgress(criteria) {

            const calculatedCriteriaCount = criteria.length === 1 ? 1 : 3;

            return Number(Math.round((criteria.reduce((accumulator, currentCriteria) => {

                if (currentCriteria.rule === 'RULE_N_REFERRALS_WITH_X_REFERRALS_EACH' && criteria.length >= 4) {
                    return 0 + accumulator;
                }

                return (currentCriteria.actual_value / currentCriteria.target_value) + accumulator
            }, 0) / calculatedCriteriaCount) * 10000) + 'e-2');
        }
    }
}
</script>

<style lang="scss">
.progress {
    font-size: 13px;

    .positive-text {
        font-size: 13px;
    }
}

.progress-number {
    padding-bottom: 2px;
    font-weight: 100;
}
</style>